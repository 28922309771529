import React, { Component } from 'react';
import Layout from '../components/layout';
import { Sidebar } from '../components/sidebar';
import { graphql } from 'gatsby';
import { Tile } from '../components/tile';
import tileStyle from '../components/tile.module.scss';
import { MapboxMap } from '../components/mapbox-map';
import { Footer } from '../components/footer';
import { isEqual } from 'lodash';
import { TitleText } from '../components/title-text';

export default class MapPage extends Component {
  constructor(props) {
    super(props);

    const { districts, pageData } = props.data;
    this.tiles = pageData.edges[0].node.tiles;
    this.fallBackHover = [];
    this.pageDescription = pageData.edges[0].node.description;
    this.pageTitle = pageData.edges[0].node.title;
    this.pageSubTitle = pageData.edges[0].node.subTitle;
    // TODO: maybe move this into gatsby-node
    districts.edges
      .filter(({ node }) => {
        const parts = (node.page || '').split('/');
        return parts.length === 2 && parts[0] === 'map';
      })
      .forEach(({ node }) => {
        const correspondingTile = this.tiles.find(tile => tile.link === node.page);
        if (correspondingTile) {
          correspondingTile.mapFeatures = node.mapFeatures;
          if (correspondingTile.mapFeatures) {
            this.fallBackHover.push(...node.mapFeatures);
          }
        }
      });
    this.update = true;

    const { map } = pageData.edges[0].node;
    this.state = {
      latlng: {
        lat: map.lat,
        lng: map.lng
      },
      zoom: map.zoom,
      mapStyle: map.styles[0].link,
      hoveredElement: this.fallBackHover
    };
  }

  componentDidUpdate() {
    this.update = true;
  }

  handleClick = (_, e) => {
    this.setState({
      latlng: [e.lngLat.lng, e.lngLat.lat],
      zoom: 14
    });
  };

  render() {
    let tileClasses = tileStyle.tile;

    // const activeTile = this.tiles.find(e => e.link === this.state.hoveredElement);

    return (
      <Layout>
        <MapboxMap
          updatePosition={this.update}
          center={[this.state.latlng.lat, this.state.latlng.lng]}
          zoom={this.state.zoom}
          mapFeatures={this.state.hoveredElement}
          mapStyle={this.state.mapStyle}
          minZoom={this.props.data.pageData.edges[0].node.map.minZoom}
          maxZoom={this.props.data.pageData.edges[0].node.map.maxZoom}
        />
        <Sidebar
          render={current => (
            <>
              {this.pageTitle.length > 0 && this.pageSubTitle.length > 0 && (
                <TitleText
                  title={this.pageTitle}
                  subTitle={this.pageSubTitle}
                  description={this.pageDescription}
                  colorCode="#1672a0"
                  textHighlightColor="#fff"
                />
              )}

              <div className={tileStyle.tileWrapper}>
                {this.tiles.map(({ mapFeatures, description, ...e }) => (
                  <Tile
                    key={e.title}
                    className={
                      tileClasses +
                      ' ' +
                      tileStyle[current] +
                      (Boolean(e.needs) && e.needs.length > 0 ? ' ' + tileStyle['disabled'] : ' da')
                    }
                    description={
                      <span>
                        {description} <b>mehr...</b>
                      </span>
                    }
                    {...e}
                    onMouseOver={() => {
                      this.update = false;
                      const activeTile = this.tiles.find(el => el.link === e.link);
                      this.setState({
                        hoveredElement: activeTile.mapFeatures
                          ? this.fallBackHover.filter(
                              item => !isEqual(item, activeTile.mapFeatures[0])
                            )
                          : this.fallBackHover
                      });
                    }}
                    onMouseOut={() => {
                      this.update = false;
                      this.setState({ hoveredElement: this.fallBackHover });
                    }}
                  />
                ))}
              </div>
            </>
          )}
        />
        <Footer />
      </Layout>
    );
  }
}

export const query = graphql`
  {
    pageData: allDataJson(filter: { page: { eq: "map" } }) {
      edges {
        node {
          title
          subTitle
          description {
            title
            text
          }
          map {
            lat
            lng
            zoom
            minZoom
            maxZoom
            styles {
              link
              icon
            }
          }
          tiles {
            image {
              file
            }
            title
            needs
            lastUpdated
            colorCode
            textHighlightColor
            letter
            description
            link
          }
        }
      }
    }
    districts: allDataJson {
      edges {
        node {
          page
          mapFeatures {
            fillProperties {
              fill_color
              fill_opacity
            }
            shape
          }
        }
      }
    }
  }
`;
