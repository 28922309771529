import React from 'react';
import { Link } from '@reach/router';
import Layout from '../components/layout';
import { Sidebar } from '../components/sidebar';
import tileStyle from '../components/tile.module.scss';
import { Tile } from '../components/tile';
import { Arrow } from '../components/arrow';
import { CloseButton } from '../components/closeButton';
import sidebarStyle from '../components/sidebar.module.scss';
import { MapboxMap } from '../components/mapbox-map';
import { Footer } from '../components/footer';
import { TitleText } from '../components/title-text';

export default ({ pageContext }) => {
  const {
    colorCode,
    title,
    subTitle,
    variants,
    description,
    previous,
    next,
    map,
    needs
  } = pageContext;

  console.log('map-page', needs);

  let tileClasses = tileStyle.tile;
  return (
    <Layout>
      <MapboxMap
        center={[map.lat, map.lng]}
        zoom={map.zoom}
        minZoom={map.minZoom}
        maxZoom={map.maxZoom}
      />
      <Sidebar
        render={current => (
          <>
            <div className={sidebarStyle.buttonBar}>
              {previous && (
                <Link to={previous}>
                  <Arrow.Left aria-label="Vorherige Seite" />
                </Link>
              )}
              {next && (
                <Link to={next}>
                  <Arrow.Right aria-label="Nächste Seite" />
                </Link>
              )}
              <Link to="/map" title="Zurück">
                <CloseButton className={sidebarStyle.closePage} />
              </Link>
            </div>

            <TitleText
              description={description}
              title={title}
              subTitle={subTitle}
              colorCode={colorCode}
            />

            <div className={tileStyle.tileWrapper}>
              {variants.map((e, i) => (
                <Tile
                  {...e}
                  key={e.title + i}
                  className={tileClasses + ' ' + tileStyle[current]}
                  letter={e.tileTitle}
                  colorCode={colorCode}
                />
              ))}
            </div>
          </>
        )}
      />
      <Footer />
    </Layout>
  );
};
