import React, { Component, Fragment } from 'react';
import { Link, graphql } from 'gatsby';

import { ModuleText } from '../components/module-text';
import { ModuleProcess } from '../components/module-process';
import { ModuleSlider } from '../components/module-slider';
import SEO from '../components/seo';
import { Button } from '../components/button';
import Layout from '../components/layout';
import StyledScrollbars from '../components/styled-scrollbars';
import { Container } from '../components/container';
import { Footer } from '../components/footer';
import { ModuleTextImage } from '../components/module-text-image';
import { ArrowDown } from '../components/icons/arrow-down';

import stylesArrow from '../templates/variant-page.module.scss';
import { FlipClock } from '../components/flip-clock';

class StartPage extends Component {
  constructor(props) {
    super(props);

    this.state = { isSafari: false, isArrowVisible: true, isMobile: false };
  }

  componentDidMount() {
    this.setState({
      isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    });

    window.addEventListener('resize', this.onResize.bind(this));
    this.onResize();

    // quick & dirty fix to add target="_blank" to link embedded in markdown
    const link = document.querySelector(
      'a[href="/2019-11-19_Fragen_Podiumsdiskussion_SG11_52.pdf"]'
    );
    if (link) {
      link.setAttribute('target', '_blank');
    }

    const link2 = document.querySelector('a[href="/2019_11_27-ErgebnisdokumentationUSH.pdf"]');
    if (link2) {
      link2.setAttribute('target', '_blank');
    }
  }

  onScroll = event => {
    if (event.target.scrollTop > 90) {
      this.setState({ isArrowVisible: false });
    }
  };

  onResize = () => {
    this.setState({ isMobile: window.innerWidth < 981 ? true : false });
  };

  render() {
    const { data } = this.props;
    const { isArrowVisible, isMobile } = this.state;

    return (
      <Layout>
        <StyledScrollbars onScroll={isArrowVisible ? this.onScroll : () => {}}>
          <div style={{ position: 'relative', minHeight: '100%', paddingBottom: '170px' }}>
            <SEO title="Startseite" keywords={[`phvision`, `bürgerbeteiligung`]} />

            <Container
              modifier="contentWidth"
              style={{ display: this.state.isSafari ? 'block' : 'none' }}
            >
              <span
                style={{
                  marginBottom: '30px',
                  display: 'block',
                  color: 'red',
                  border: '1px dashed red',
                  padding: '12px'
                }}
              >
                Bitte wechseln Sie den Browser, da Safari die Kartendarstellung nicht fehlerfrei
                darstellen kann.
              </span>
            </Container>

            {isMobile && (
              <Container modifier="contentWidth">
                <span
                  style={{
                    marginBottom: '30px',
                    display: 'block',
                    color: 'red',
                    border: '1px dashed red',
                    padding: '12px',
                    fontSize: 28
                  }}
                >
                  Aufgrund des Karten-Tools ist diese Website nicht für Mobiltelefone optimiert.
                  Benutzen Sie bitte ein Tablet(Querformat) oder PC für eine optimale Darstellung.
                </span>
              </Container>
            )}

            {isArrowVisible && (
              <ArrowDown
                className={stylesArrow.arrowDown}
                style={{
                  width: '180px',
                  height: '180px',
                  position: 'fixed',
                  bottom: 30,
                  left: 'calc(50% - 90px)',
                  zIndex: '10'
                }}
              />
            )}

            {data.allDataJson.edges[0].node.content.map(({ type, ...props }) => {
              switch (type) {
                case 'module-text':
                  return (
                    <Container
                      key={type + props.title}
                      modifier="contentWidth"
                      style={{ marginBottom: '150px' }}
                    >
                      <ModuleText key={type + props.title} {...props} />
                    </Container>
                  );
                case 'module-process':
                  return (
                    <Container
                      key={type + props.title}
                      modifier="contentWidth"
                      style={{ marginBottom: '150px' }}
                    >
                      <ModuleProcess key={type + props.title} {...props} />
                    </Container>
                  );
                case 'module-slider':
                  return (
                    <Fragment key={type + props.title}>
                      <hr style={{ margin: '195px 0 35px 0' }} />
                      <Container modifier="contentWidth">
                        <ModuleSlider {...props} />
                      </Container>
                      <hr style={{ margin: '35px 0 180px 0' }} />
                    </Fragment>
                  );
                case 'module-text-image':
                  return (
                    <Fragment key={type + props.title}>
                      <hr style={{ margin: '195px 0 35px 0' }} />
                      <Container modifier="contentWidth">
                        <ModuleTextImage {...props} />
                      </Container>
                      <hr style={{ margin: '35px 0 180px 0' }} />
                    </Fragment>
                  );
                case 'module-countdown':
                  return (
                    <Fragment key={type + props.title}>
                      <hr style={{ margin: '195px 0 35px 0' }} />
                      <Container modifier="contentWidth">
                        <FlipClock {...props} />
                      </Container>
                      <hr style={{ margin: '35px 0 180px 0' }} />
                    </Fragment>
                  );
                default:
                  console.warn(`Unhandled case ${type}`);
              }
              return null;
            })}
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
              <Link to="/map" title="Zum Unterschleißheim">
                <Button modifier={'bigRoundPlusButton'}>
                  <span>Start</span>
                </Button>
              </Link>
              <a
                href="/2019-09-12_USH_Neues_Quartier_Auswertungsergebnisse_Gesamt.pdf"
                title="Zur Auswertung"
              >
                <Button
                  modifier={'bigRoundPlusButton'}
                  style={{ marginLeft: 50, padding: '7px 20px' }}
                >
                  <span style={{ fontSize: 28 }}>Zur Auswertung</span>
                </Button>
              </a>
            </div>
            <Footer fixed={false} />
          </div>
        </StyledScrollbars>
      </Layout>
    );
  }
}

export default StartPage;

export const query = graphql`
  query {
    allDataJson(filter: { page: { eq: "start" } }) {
      edges {
        node {
          content {
            type
            title
            text
            endDate
            sections {
              image {
                file
              }
              text
              type
              link
            }
          }
        }
      }
    }
  }
`;
