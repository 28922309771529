import React from 'react';
// import postStyle from './post-tile.module.scss';
import style from './comment.module.scss';

// {
//   "comment": "Super Vorschlag!",
//   "created": "2019-03-20T08:55:52Z",
//   "liked": false,
//   "likes": 0,
//   "slug": "lionralfs_mein_supertoller_vorschlag",
//   "user": {
//     "username": "lionralfs",
//     "department": "Software Developer",
//     "avatar": null,
//     "roles": [
//       "ROLE_USER"
//     ],
//     "email": "lion@webschuppen.com"
//   },
//   "visible": true
// }

const dateToTimeAgo = dateString => {
  const date = new Date(dateString);
  const seconds = Math.floor((new Date() - date) / 1000);
  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    return `Vor ${interval} ${interval === 1 ? 'Jahr' : 'Jahren'}`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return `Vor ${interval} ${interval === 1 ? 'Monat' : 'Monaten'}`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return `Vor ${interval} ${interval === 1 ? 'Tag' : 'Tagen'}`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return `Vor ${interval} ${interval === 1 ? 'Stunde' : 'Stunden'}`;
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return `Vor ${interval} ${interval === 1 ? 'Minute' : 'Minuten'}`;
  }
  return `Vor weniger als einer Minute`;
};

export const Comment = props => {
  return (
    <div className={style.comment}>
      <div className={style.topBar}>
        {props.username && <span className={style.username}>{props.user.username}</span>}{' '}
        <span className={style.createdAt}>{dateToTimeAgo(props.created)}</span>
      </div>
      <div className={style.commentText}>
        <span>{props.comment}</span>
      </div>
      <div className={style.actions}>
        {/* <span>Kommentieren</span> */}
        {/* <span className={postStyle.likeButton}>
          <div className={postStyle.countBox}>{props.likes}</div>
          <img
            onClick={props.onLike}
            src={require(`../images/heart-${props.liked ? 'filled' : 'outline'}.png`)}
            alt="like"
            className="likeButton"
          />
        </span> */}
      </div>
    </div>
  );
};
