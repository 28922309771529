import React from 'react';
import { isFunction } from 'util';
import { Info } from './info';

import postCreateStyle from '../../components/post-create.module.scss';
import style from './select.module.scss';

/**
 *
 * @param {object} props
 */
const Birthyear = props => {
  /**
   * @param {import('react').ChangeEvent<HTMLSelectElement>} event
   */
  const onChange = event => {
    const birthyear = event.target.value;

    if (isFunction(props.onChange)) props.onChange(birthyear);
  };

  const { title, info, value, options } = props;

  return (
    <>
      <div className={postCreateStyle.sectionLeft}>
        {title}
        <br />
        <div className={style.select}>
          <select name="birthyear" value={value} onChange={onChange}>
            {options.map(year => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={postCreateStyle.sectionRight}>{info.length > 0 && <Info text={info} />}</div>
    </>
  );
};

export { Birthyear };
