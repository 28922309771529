import React, { Component } from 'react';
import { Dialog } from '@reach/dialog';
import '@reach/dialog/styles.css';
import { isFunction } from 'util';
import { ModuleVideoGallery } from './module-video-gallery';
import { Transition } from 'react-spring/renderprops';
import './lightbox.scss';
import { graphql, StaticQuery } from 'gatsby';
import { MagGlass } from './icons/mag-glass';

export class Lightbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLightbox: false
    };
  }

  open = () => {
    this.setState({ showLightbox: true });
  };

  close = e => {
    if (
      e.target.getAttribute('type') !== 'button' &&
      !e.target.src &&
      !e.target.getAttribute('viewBox')
    ) {
      this.setState({ showLightbox: false });
    }
  };

  render() {
    const { showLightbox } = this.state;

    return (
      <>
        {isFunction(this.props.children) && this.props.children({ openLightbox: this.open })}
        <Transition
          items={showLightbox}
          from={{ opacity: 0, y: -10 }}
          enter={{ opacity: 1, y: 0 }}
          leave={{ opacity: 0, y: 10 }}
        >
          {show =>
            show &&
            (props => (
              <Dialog
                style={{ position: 'relative', padding: '60px', opacity: props.opacity }}
                className="lightbox"
                onClick={this.close}
              >
                {this.props.content}
                <button
                  type="button"
                  onClick={() => this.setState({ showLightbox: false })}
                  style={{
                    position: 'absolute',
                    top: 15,
                    right: 10,
                    border: 0,
                    background: 'transparent',
                    color: 'white',
                    cursor: 'pointer',
                    fontSize: 40,
                    outline: 'none'
                  }}
                >
                  ✕
                </button>

                <StaticQuery
                  query={graphql`
                    query {
                      images: allFile {
                        edges {
                          node {
                            relativePath
                            name
                            publicURL
                          }
                        }
                      }
                    }
                  `}
                  render={data => {
                    const image = data.images.edges.find(n => {
                      return n.node.relativePath.includes(this.props.filename);
                    });

                    if (!image) {
                      return null;
                    }

                    return (
                      <a
                        href={image.node.publicURL}
                        target="_blank"
                        title="Bild vergrößern"
                        rel="noopener noreferrer"
                        style={{
                          position: 'absolute',
                          top: 15,
                          left: '50%',
                          transform: 'translateX(-50%)'
                        }}
                      >
                        <MagGlass width="25" height="25" />
                      </a>
                    );
                  }}
                />
              </Dialog>
            ))
          }
        </Transition>
      </>
    );
  }
}

export const withLightbox = Component => {
  const image = Component();

  return (
    <Lightbox content={<Component />} filename={image.props.filename}>
      {({ openLightbox }) => (
        <div onClick={openLightbox} style={{ cursor: 'pointer' }}>
          <Component />
        </div>
      )}
    </Lightbox>
  );
};

export const withLightBoxGallery = (Component, items) => {
  const gallery = <ModuleVideoGallery items={items} />;
  return (
    <Lightbox content={gallery}>
      {({ openLightbox }) => (
        <div onClick={openLightbox} style={{ cursor: 'pointer' }}>
          <Component />
        </div>
      )}
    </Lightbox>
  );
};
