import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Info } from './info';
import { isFunction, isInShape } from '../../utils';

import postCreateStyle from '../../components/post-create.module.scss';
import style from './map.module.scss';

require('mapbox-gl/dist/mapbox-gl.css'); // TODO: only include this on pages with a leaflet map

let LMap, Marker, icon, GeoJSONLayer, MapboxGL;

export class MapModule extends Component {
  componentDidMount() {
    // let ReactLeaflet = require('react-leaflet');
    let ReactMapboxGl = require('react-mapbox-gl');
    MapboxGL = require('mapbox-gl');
    LMap = ReactMapboxGl.Map({
      accessToken: 'pk.eyJ1IjoiY2hlZmZlbiIsImEiOiJDNDA5QkI0In0.tnM3DspM5Kesse6J0YrR_g',
      minZoom: 11.8,
      maxZoom: 17
    });
    Marker = ReactMapboxGl.Marker;
    GeoJSONLayer = ReactMapboxGl.GeoJSONLayer;
    icon = require(`../../images/${this.props.markerIcon || 'your-post.png'}`);

    this.forceUpdate();
  }

  handleClick = (map, e) => {
    if (isFunction(this.props.onChange)) {
      if (
        this.props.shape &&
        this.props.shape[0] &&
        isInShape([e.lngLat.lng, e.lngLat.lat], this.props.shape[0].shape)
      ) {
        if (isFunction(this.props.handleIsInShape)) this.props.handleIsInShape(true);

        this.props.onChange({ latitude: e.lngLat.lat, longitude: e.lngLat.lng });
      } else {
        if (isFunction(this.props.handleIsInShape)) this.props.handleIsInShape(false);
      }
    }
  };

  onMapLoad = map => {
    map.addControl(new MapboxGL.FullscreenControl());
  };

  render() {
    const { marker, shape } = this.props;
    const hasSpecialIcon = Boolean(this.props.markerIcon);

    return (
      <>
        <h2>
          {!this.props.required && <span className={postCreateStyle.optionalTag}>optional</span>}
          {this.props.title}
        </h2>
        <div
          className={postCreateStyle.sectionLeft}
          style={{ width: this.props.mapOnly ? '100%' : '60%' }}
        >
          <StaticQuery
            query={graphql`
              {
                allDataJson(filter: { page: { eq: "map" } }) {
                  edges {
                    node {
                      map {
                        lat
                        lng
                        minZoom
                        maxZoom
                        zoom
                        styles {
                          link
                          icon
                        }
                      }
                    }
                  }
                }
              }
            `}
            render={data => {
              const { map } = data.allDataJson.edges[0].node;
              const { lng, lat } =
                this.props.center && this.props.center.lat && this.props.center.lng
                  ? this.props.center
                  : map;
              return (
                <>
                  {LMap && ( // prevent Leaflet from being server side rendered
                    <LMap
                      animate={true}
                      center={{ lng, lat }}
                      zoom={[this.props.zoom || map.zoom || 15]}
                      minZoom={this.props.minZoom || map.minZoom}
                      maxZoom={this.props.maxZoom || map.maxZoom}
                      style={
                        this.props.mapStyle ||
                        map.styles[0].link ||
                        'mapbox://styles/cheffen/cjv2brtuk097i1flrt8b4lv2v'
                      }
                      containerStyle={{ height: 300 }}
                      className={style.map}
                      onClick={this.handleClick}
                      onStyleLoad={this.onMapLoad}
                    >
                      {marker && marker.latitude && marker.longitude && (
                        <Marker
                          coordinates={{ lat: marker.latitude, lng: marker.longitude }}
                          anchor={hasSpecialIcon ? 'bottom' : 'bottom-left'}
                          offset={[hasSpecialIcon ? 0 : -15, 0]}
                        >
                          <img src={icon} alt="Marker" />
                        </Marker>
                      )}
                      {shape && shape[0] && (
                        <GeoJSONLayer
                          key={shape[0][0]}
                          linePaint={{
                            'line-color': 'rgb(0, 149, 219)',
                            'line-width': 3
                          }}
                          data={{
                            type: 'Feature',
                            geometry: {
                              type: 'Polygon',
                              coordinates: [shape[0].shape]
                            }
                          }}
                        />
                      )}
                    </LMap>
                  )}
                </>
              );
            }}
          />
        </div>
        {!this.props.mapOnly && (
          <div className={postCreateStyle.sectionRight} anchor="bottom">
            <Info text={this.props.info} />
          </div>
        )}
      </>
    );
  }
}
