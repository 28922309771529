import React from 'react';
import Layout from '../components/layout';
import { Link } from '@reach/router';
import { Sidebar } from '../components/sidebar';
import { Tab } from '../components/tab';
import { ModuleCol2 } from '../components/module-col-2';
import { ModuleTextImage } from '../components/module-text-image';
import { Arrow } from '../components/arrow';
import sidebarStyle from '../components/sidebar.module.scss';
import { CloseButton } from '../components/closeButton';
import { MapboxMap } from '../components/mapbox-map';
import { ArrowDown } from '../components/icons/arrow-down';
import { ModuleVideoGallery } from '../components/module-video-gallery';
import { Footer } from '../components/footer';
import variantPageStyle from './variant-page.module.scss';
import overviewStyle from '../templates/overview.module.scss';

const noop = () => {};
let Marker;
let lastWindowWidth;
export default class VariantPage extends React.Component {
  state = {
    arrowVisible: true,
    activeMarkers: [],
    screenSize: 'small'
  };

  componentDidMount() {
    const ReactMapboxGl = require('react-mapbox-gl');
    Marker = ReactMapboxGl.Marker;

    this.onWindowResize();
    window.addEventListener('resize', this.onWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  onWindowResize = () => {
    lastWindowWidth = window.innerWidth;
    if (lastWindowWidth < 1330) {
      this.setState({ screenSize: 'small' });
    } else if (lastWindowWidth < 1850) {
      this.setState({ screenSize: 'medium' });
    } else {
      this.setState({ screenSize: 'large' });
    }
  };

  onScroll = e => {
    if (e.target.scrollTop > 90) {
      this.setState({ arrowVisible: false });
    }
  };

  handleCollapse = id => {
    if (id && this.state.activeMarkers.indexOf(id) !== -1) {
      this.setState({ activeMarkers: this.state.activeMarkers.filter(e => e !== id) });
    }
  };

  handleExpand = id => {
    if (id && this.state.activeMarkers.indexOf(id) === -1) {
      this.setState({ activeMarkers: [...this.state.activeMarkers, id] });
    }
  };

  render() {
    const { pageContext } = this.props;
    const {
      colorCode,
      textHighlightColor,
      title,
      subTitle,
      tabs,
      parent,
      previous,
      next,
      // allVariants,
      page,
      needs,
      map,
      description
    } = pageContext;

    console.log('variant-page', needs);

    const {
      // screenSize,
      activeMarkers,
      arrowVisible
    } = this.state;

    const markers = tabs
      .filter(({ markers, id }) => markers && id && activeMarkers.indexOf(id) !== -1)
      .reduce((acc, { markers }) => [...acc, ...markers], []);

    return (
      <Layout>
        <MapboxMap
          center={[map.lat, map.lng]}
          zoom={map.zoom}
          minZoom={map.minZoom}
          maxZoom={map.maxZoom}
          mapStyle={map.style}
        >
          {markers.map(({ lat, lng, text }) => (
            <Marker
              anchor={'bottom-left'}
              key={text}
              coordinates={{
                lat,
                lng
              }}
            >
              <span
                style={{
                  color: '#fff',
                  background: '#0099d5',
                  padding: '5px',
                  position: 'relative'
                }}
              >
                <i
                  style={{
                    width: 0,
                    height: 0,
                    borderLeft: '0px solid transparent',
                    borderRight: '9px solid transparent',
                    borderTop: '13px solid #0099d5',
                    position: 'absolute',
                    bottom: '-13px',
                    left: 0
                  }}
                />
                {text}
              </span>
            </Marker>
          ))}
        </MapboxMap>
        <Sidebar
          onScroll={arrowVisible ? this.onScroll : noop}
          render={currentSideBarState => (
            <>
              {arrowVisible && (
                <ArrowDown
                  className={variantPageStyle.arrowDown}
                  style={{
                    width: '180px',
                    height: '180px',
                    position: 'absolute',
                    bottom: '30px',
                    left: 'calc(50% - 90px)',
                    zIndex: '10'
                  }}
                />
              )}
              <div className={sidebarStyle.buttonBar}>
                {previous && (
                  <Link to={previous}>
                    <Arrow.Left aria-label="Vorherige Seite" />
                  </Link>
                )}
                {next && (
                  <Link to={next}>
                    <Arrow.Right aria-label="Nächste Seite" />
                  </Link>
                )}
                <Link to={parent} title="Zurück">
                  <CloseButton className={sidebarStyle.closePage} />
                </Link>
              </div>

              <div
                style={{
                  backgroundColor: colorCode,
                  color: textHighlightColor || '#000'
                }}
              >
                <h2
                  className={overviewStyle.overviewHeader}
                  style={{ backgroundColor: colorCode, color: textHighlightColor || '#000' }}
                >
                  <span className={overviewStyle.overviewTitle}>{title}</span> | 
                  <span>{subTitle}</span>
                </h2>

                <div
                  style={{
                    width: '100%',
                    padding: '0 22px 20px'
                  }}
                >
                  {description && description.text.length > 0 && (
                    <p style={{ fontSize: 19, fontFamily: 'Roboto', fontWeight: '300' }}>
                      {description.text}
                    </p>
                  )}

                  <h2
                    style={{
                      textTransform: 'uppercase',
                      fontSize: 18,
                      textAlign: 'center',
                      marginTop: 40
                    }}
                  >
                    Hier können Sie einen Beitrag verfassen
                  </h2>
                  <div className={variantPageStyle.toPostsButton}>
                    <Link to={`${page}/posts`} className={variantPageStyle.link} />
                    <img
                      src={require('../images/speech-bubbles-white.png')}
                      alt="Alle Beiträge"
                      style={{
                        margin: '10px 0 auto',
                        marginBottom: '5px',
                        display: 'block'
                      }}
                    />
                    Sprich mit!
                  </div>
                </div>
              </div>
              <br />

              {tabs.map(({ title, content, id, download }) => {
                return (
                  <Tab
                    title={title}
                    download={download}
                    colorCode={colorCode}
                    textHighlightColor={textHighlightColor}
                    key={title}
                    onCollapsed={() => this.handleCollapse(id)}
                    onExpanded={() => this.handleExpand(id)}
                  >
                    {function(toggleTab) {
                      return content.map(({ type, ...props }) => {
                        switch (type) {
                          case 'module-text-image':
                            return (
                              <ModuleTextImage
                                {...props}
                                key={type + props.title}
                                sidebarView={currentSideBarState}
                              />
                            );
                          case 'teaser':
                          case 'module-col-2':
                            return (
                              <ModuleCol2
                                {...props}
                                key={type + props.left.title}
                                sidebarView={currentSideBarState}
                                colorCode={colorCode}
                                textHighlightColor={textHighlightColor}
                                teaserClickHandler={type === 'teaser' && toggleTab}
                              />
                            );
                          case 'module-video-gallery':
                            const { gallery_items, ...rest } = props;
                            return (
                              <ModuleVideoGallery items={gallery_items} {...rest} key={type} />
                            );
                          default:
                            console.warn('No rendering definition for ', type, props);
                            return null;
                        }
                      });
                    }}
                  </Tab>
                );
              })}

              <div
                style={{
                  backgroundColor: colorCode,
                  color: textHighlightColor || '#000',
                  width: '100%',
                  textAlign: 'center',
                  padding: '20px 0'
                }}
              >
                <h2 style={{ textTransform: 'uppercase', fontSize: 20 }}>
                  Hier können Sie einen Beitrag verfassen
                </h2>
                <div className={variantPageStyle.toPostsButton}>
                  <Link to={`${page}/posts`} className={variantPageStyle.link} />
                  <img
                    src={require('../images/speech-bubbles-white.png')}
                    alt="Alle Beiträge"
                    style={{
                      margin: '10px 0 auto',
                      marginBottom: '5px',
                      display: 'block'
                    }}
                  />
                  Sprich mit!
                </div>
              </div>
            </>
          )}
        />
        <Footer />
      </Layout>
    );
  }
}

// let TinySlider = null;
// class VariantsSlider extends React.Component {
//   componentDidMount() {
//     TinySlider = require('tiny-slider-react').default;
//     this.forceUpdate();
//   }

//   onGoTo = dir => this.ts.slider.goTo(dir);

//   render() {
//     const visibility =
//       this.props.settings.items < this.props.children.length ? 'visible' : 'hidden';
//     return (
//       TinySlider && (
//         <>
//           <div style={{ width: '100px', position: 'relative', visibility }}>
//             <Arrow.Left
//               style={{
//                 position: 'absolute',
//                 left: '50%',
//                 top: '50%',
//                 transform: 'translate(-50%, -50%)',
//                 cursor: 'pointer'
//               }}
//               aria-label="Vorheriges Element"
//               onClick={() => this.onGoTo('prev')}
//             />
//           </div>
//           <TinySlider {...this.props} ref={ts => (this.ts = ts)} />
//           <div style={{ width: '100px', position: 'relative', visibility }}>
//             <Arrow.Right
//               style={{
//                 position: 'absolute',
//                 left: '50%',
//                 top: '50%',
//                 transform: 'translate(-50%, -50%) rotate(180deg)',
//                 cursor: 'pointer'
//               }}
//               aria-label="Vorheriges Element"
//               onClick={() => this.onGoTo('next')}
//             />
//           </div>
//         </>
//       )
//     );
//   }
// }
