import React, { Component } from 'react';
import { Button } from './button';
import { Container } from './container';
import { getCommentsPost, commentPost, likeComment } from '../api';
import SlideToggle from 'react-slide-toggle';
import { Comment } from './comment';
import Captcha from '../modules/post/captcha';

export class CommentSection extends Component {
  state = {
    comments: [],
    ownComment: '',
    email: 'email',
    emailIsValid: true,
    captchaInput: '',
    captchaCode: undefined,
    captchaError: false
  };

  componentDidMount() {
    this.fetchComments();
  }

  fetchComments() {
    getCommentsPost({ slug: this.props.slug }).then(([comments, err]) => {
      if (!err && Array.isArray(comments)) {
        this.setState({ comments });
      }
    });
  }

  updateOwnComment = e => {
    this.setState({ ownComment: e.target.value });
  };

  submitComment = e => {
    this.setState({ captchaError: false });

    if (this.state.captchaCode !== this.state.captchaInput) {
      this.setState({ captchaError: true });
      return;
    }

    commentPost({
      slug: this.props.slug,
      comment: this.state.ownComment,
      email: this.state.email
    }).then(([_, err]) => {
      if (!err) {
        this.setState({ ownComment: '' });
        this.fetchComments();
      } else {
        console.error(err);
      }
    });
  };

  likeComment = slug => {
    likeComment({ slug }).then(([_, err]) => {
      if (!err) {
        this.fetchComments();
      } else {
        console.error(err);
      }
    });
  };

  onCaptchaInputChange = e => {
    this.setState({ captchaInput: e.target.value });
  };

  render() {
    return (
      <section
        style={{
          borderBottom: 'none',
          borderTop: '2px solid #c1c1c1',
          marginTop: '20px'
        }}
      >
        <Container modifier="contentWidthOverlay">
          <h2>Wie ist ihre Perspektive hierzu?</h2>

          <div style={{ padding: '0 80px' }}>
            <SlideToggle
              collapsed={true}
              duration={800}
              noDisplayStyle
              render={({ onToggle, setCollapsibleElement, toggleState, progress }) => (
                <div style={{ marginBottom: '20px' }}>
                  <Button onClick={onToggle} style={{ marginBottom: '20px' }}>
                    Kommentar schreiben
                  </Button>
                  <div style={{ overflow: 'hidden' }} ref={setCollapsibleElement}>
                    <textarea value={this.state.ownComment} onChange={this.updateOwnComment} />
                    <Captcha
                      length={4}
                      height="40"
                      width="150"
                      style={{ background: '#fff', position: 'relative', pointerEvents: 'none' }}
                      codes={[1, 2, 3, 4, 5, 6, 7, 8, 9, 0]}
                      onChange={code => {
                        this.setState({ captchaCode: String(code) });
                      }}
                    />
                    <input
                      type="text"
                      onChange={this.onCaptchaInputChange}
                      value={this.state.captchaInput}
                    ></input>
                    {this.state.captchaError && (
                      <span
                        style={{
                          display: 'block',
                          marginBottom: '15px',
                          color: 'red'
                        }}
                      >
                        Der eingegebene Captcha-Code stimmt nicht mit dem angezeigten Code überein.
                      </span>
                    )}
                    <Button onClick={this.submitComment} style={{ marginRight: '20px' }}>
                      Absenden
                    </Button>
                    <Button onClick={onToggle}>Abbrechen</Button>
                  </div>
                </div>
              )}
            />

            {this.state.comments.map(e => (
              <Comment key={e.slug} {...e} onLike={() => this.likeComment(e.slug)} />
            ))}
          </div>
        </Container>
      </section>
    );
  }
}
