import React from 'react';
import { Link } from '@reach/router';
import Layout from '../components/layout';
import { Tab } from '../components/tab';
import { ModuleCol2 } from '../components/module-col-2';
import { ModuleTextImage } from '../components/module-text-image';
import { Sidebar } from '../components/sidebar';
import style from './overview.module.scss';
import { Arrow } from '../components/arrow';
import { CloseButton } from '../components/closeButton';
import sidebarStyle from '../components/sidebar.module.scss';
import { MapboxMap } from '../components/mapbox-map';
import { Footer } from '../components/footer';
import { ModuleVideoGallery } from '../components/module-video-gallery';
import { TitleText } from '../components/title-text';

export default ({ pageContext }) => {
  const { colorCode, title, subTitle, tabs, previous, next, map } = pageContext;

  return (
    <Layout modifier={style.overview}>
      <MapboxMap
        center={[map.lat, map.lng]}
        zoom={map.zoom}
        minZoom={map.minZoom}
        maxZoom={map.maxZoom}
      />
      <Sidebar
        render={currentSideBarState => {
          return (
            <>
              <div className={sidebarStyle.buttonBar}>
                {previous && (
                  <Link to={previous}>
                    <Arrow.Left aria-label="Vorherige Seite" />
                  </Link>
                )}
                {next && (
                  <Link to={next}>
                    <Arrow.Right aria-label="Nächste Seite" />
                  </Link>
                )}
                <Link to="/map" title="Zurück">
                  <CloseButton className={sidebarStyle.closePage} />
                </Link>
              </div>

              <TitleText title={title} subTitle={subTitle} colorCode={colorCode} />

              {tabs.map(({ title, content, download }) => {
                return (
                  <Tab title={title} colorCode={colorCode} key={title} download={download}>
                    {function(toggleTab) {
                      return content.map(({ type, ...props }) => {
                        switch (type) {
                          case 'module-text-image':
                            return (
                              <ModuleTextImage
                                {...props}
                                key={type + props.title}
                                sidebarView={currentSideBarState}
                              />
                            );
                          case 'teaser':
                          case 'module-col-2':
                            return (
                              <ModuleCol2
                                {...props}
                                key={type + props.left.title}
                                sidebarView={currentSideBarState}
                                colorCode={colorCode}
                                teaserClickHandler={type === 'teaser' && toggleTab}
                              />
                            );
                          case 'module-video-gallery':
                            const { gallery_items, ...rest } = props;
                            return (
                              <ModuleVideoGallery items={gallery_items} {...rest} key={type} />
                            );
                          default:
                            console.warn('No rendering definition for ', type, props);
                            return null;
                        }
                      });
                    }}
                  </Tab>
                );
              })}
            </>
          );
        }}
      />
      <Footer />
    </Layout>
  );
};
