import React from 'react';
import { Image } from './image';
import style from './module-text-image.module.scss';
import MDReactComponent from 'markdown-react-js';
import { withLightbox, withLightBoxGallery } from './lightbox';

export const ModuleTextImage = props => {
  let imageClass = style.textImage;
  if (props.sidebarView) {
    imageClass += ' ' + style[props.sidebarView];
  }

  imageClass += ' ' + style[props.isImageLeft ? 'left' : 'right'];

  const noLightbox = Boolean(props.noLightbox);

  const component =
    props.image.gallery && props.image.gallery.length > 0 ? (
      withLightBoxGallery(() => <Image filename={props.image.file} />, props.image.gallery)
    ) : noLightbox ? (
      <Image filename={props.image.file} />
    ) : (
      withLightbox(() => <Image filename={props.image.file} />)
    );

  return (
    <div className={imageClass}>
      {!props.isImageLeft && <div className={style.image}>{component}</div>}
      {props.text && <MDReactComponent className={style.text} text={props.text} />}

      {props.isImageLeft && <div className={style.image}>{component}</div>}
    </div>
  );
};
