import React from 'react';
import { Link } from '@reach/router';
import style from '../components/tile.module.scss';

export const Tile = ({
  image,
  title,
  colorCode,
  textHighlightColor,
  letter,
  description,
  lastUpdated,
  link,
  className
}) => (
  <div className={className}>
    <Link to={'/' + link}>
      <div className={style.tileImage}>
        <figure
          style={{
            paddingBottom: '76%',
            position: 'relative',
            margin: 0
          }}
        >
          <img
            alt={title}
            src={
              image && image.file && image.file.substring(0, 4) === 'http'
                ? image.file
                : require(`../images/${image.file}`)
            }
          />
        </figure>
      </div>

      <div className={style.content}>
        {letter && (
          <span
            style={{
              backgroundColor: colorCode,
              color: textHighlightColor || '#000'
            }}
            className={style.category}
          >
            {letter}
          </span>
        )}
        <h2 className={style.title}>{title}</h2>

        <p className={style.description}>{description}</p>
      </div>

      <span className={style.lastUpdated}>Stand: {lastUpdated}</span>
    </Link>
  </div>
);
