import React, { Component } from 'react';

import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import style from './module-video-gallery.module.scss';
import { PlayButton } from './icons/play-button';
import { MagGlass } from './icons/mag-glass';

const URL_REGEX = str => /^https?:\/\//.test(str);

export class ModuleVideoGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showVideo: {}
    };

    this.items = props.items.map(({ type, thumbnailSmall, thumbnailLarge, videoLink }) => ({
      thumbnail: URL_REGEX(thumbnailSmall)
        ? thumbnailSmall
        : require(`../images/${thumbnailSmall}`),
      original: URL_REGEX(thumbnailLarge) ? thumbnailLarge : require(`../images/${thumbnailLarge}`),
      embedUrl: videoLink,
      renderItem: type === 'video-yt' ? this.renderVideo : this.renderImage
    }));
  }

  onSlide = index => {
    this.resetVideo();
  };

  resetVideo = () => {
    this.setState({ showVideo: {} });
  };

  toggleShowVideo = url => {
    const state = { ...this.state };
    state.showVideo[url] = !Boolean(state.showVideo[url]);

    this.setState({
      showVideo: state.showVideo
    });
  };

  renderImage = item => {
    return (
      <div className="image-gallery-image">
        <a
          href={item.original}
          target="_blank"
          title="Bild vergrößern"
          rel="noreferrer noopener"
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            top: '-40px'
          }}
        >
          <MagGlass width="25" height="25" />
        </a>
        <img
          src={item.original}
          alt={item.originalAlt}
          srcSet={item.srcSet}
          sizes={item.sizes}
          title={item.originalTitle}
          onError={console.error}
        />
        {item.description && <span className="image-gallery-description">{item.description}</span>}
      </div>
    );
  };

  renderVideo = item => {
    return (
      <div className="image-gallery-image">
        {this.state.showVideo[item.embedUrl] ? (
          <div className={style.videoWrapper}>
            <a
              href="."
              className="close-video"
              onClick={event => {
                event.preventDefault();
                this.toggleShowVideo(item.embedUrl);
              }}
              style={{ fontSize: 0, color: 'transparent' }}
            >
              x
            </a>
            <iframe
              width="560"
              height="315"
              src={item.embedUrl}
              frameBorder="0"
              allowFullScreen
              title={item.embedUrl}
            />
          </div>
        ) : (
          <a
            href="."
            onClick={event => {
              event.preventDefault();
              this.toggleShowVideo(item.embedUrl);
            }}
            style={{ cursor: 'pointer' }}
          >
            {item.embedUrl && (
              <PlayButton
                style={{
                  position: 'absolute',
                  width: '60px',
                  height: '60px',
                  left: 'calc(50% - 25px)',
                  top: 'calc(50% - 25px)'
                }}
              />
            )}
            <img src={item.original} alt="" />
            {/* {item.description && (
              <span className="image-gallery-description" style={{ right: '0', left: 'initial' }}>
                {item.description}
              </span>
            )} */}
          </a>
        )}
      </div>
    );
  };

  render() {
    return (
      <section className={style.videoSlider}>
        <ImageGallery
          items={this.items}
          lazyLoad={false}
          onClick={this.onImageClick}
          // onImageLoad={this.onImageLoad}
          onSlide={this.onSlide}
          infinite={true}
          showBullets={false}
          showFullscreenButton={false}
          showPlayButton={false}
          showThumbnails={true}
          showIndex={false}
          showNav={true}
          isRTL={false}
          thumbnailPosition={'bottom'}
        />
      </section>
    );
  }
}
