import React from 'react';

import style from './title-text.module.scss';
import overviewStyle from '../templates/overview.module.scss';

/**
 * @param {{description: {title: string, text: string}, title: string, subTitle: string, colorCode: string, textHighlightColor?: string}} props
 */
const TitleText = props => {
  const { description, title, subTitle, colorCode, textHighlightColor } = props;

  return (
    <>
      <h2
        className={overviewStyle.overviewHeader}
        style={{ backgroundColor: colorCode, marginBottom: 0, color: textHighlightColor || '#000' }}
      >
        <span className={overviewStyle.overviewTitle}>{title}</span> | 
        <span>{subTitle}</span>
      </h2>

      {description ? (
        <div className={style.description}>
          <b className={style.descriptionTitle}>{description.title}</b>
          <p className={style.descriptionText}>{description.text}</p>
        </div>
      ) : (
        <div style={{ marginBottom: 19 }}>&nbsp;</div>
      )}
    </>
  );
};

export { TitleText };
