import React from 'react';
import style from './post-create.module.scss';
import { Container } from './container';
import { CloseButton } from './closeButton';
import StyledScrollbars from './styled-scrollbars';
import postStyle from '../components/post-tile.module.scss';
import { MapModule } from '../modules/post/map';
import { CommentSection } from './comment-section';

const API_ENDPOINT = process.env.GATSBY_API_URL;

export const PostDetail = ({
  onClose,
  onLike,
  onBlame,
  category,
  comments,
  content,
  coordinates,
  created,
  image,
  blamed,
  liked,
  likes,
  own,
  slug,
  tags,
  title,
  user,
  variant,
  visible,
  categoryObj,
  alias,
  map
}) => {
  console.log(alias);
  return (
    <>
      <div className={style.postWrapper}>
        <div className={style.post}>
          <div className={style.closeRow}>
            <Container modifier="contentWidthOverlay">
              <CloseButton onClick={onClose} />
            </Container>
          </div>
          <div style={{ height: 'calc(100% - 65px)' }}>
            <StyledScrollbars>
              <Container modifier="contentWidthOverlay">
                <section
                  className={style.noBorder}
                  style={{
                    marginBottom: 50,
                    float: 'left',
                    width: '60%',
                    position: 'relative',
                    paddingLeft: '80px'
                  }}
                >
                  <img
                    src={
                      categoryObj.image.file.startsWith('http')
                        ? categoryObj.image.file
                        : require('../images/' + categoryObj.image.file)
                    }
                    width="60"
                    height="60"
                    alt="Fragezeichen"
                    style={{ position: 'absolute', left: '0', top: '45px' }}
                  />
                  <h2>{title}</h2>
                  <div style={{ marginBottom: '20px' }}>{content}</div>
                  <div style={{ marginBottom: '30px' }}>
                    <b>Kategorie:</b> <span>{category}</span>
                  </div>
                  {!blamed && (
                    <span
                      style={{
                        cursor: 'pointer',
                        float: 'right',
                        marginLeft: '14px',
                        marginTop: '2px'
                      }}
                      onClick={onBlame}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 485.249 485.249"
                        style={{ width: '25px', height: '25px' }}
                      >
                        <g fill="#0095db">
                          <path d="M481.249 420.625l-219-380c-4-7-11-11-19-11s-15 4-19 11l-221 382c-10 17 5 33 19 33h442c12 0 21-10 21-22 0-5-1-9-4-13zm-421-9l183-317 183 317h-366z" />
                          <path d="M221.249 192.625v97c0 12 10 22 22 22s22-10 22-22v-97c0-12-10-22-22-22s-22 10-22 22zM243.249 330.625c-15 0-27 12-27 27s12 28 27 28 27-13 27-28-12-27-27-27z" />
                        </g>
                      </svg>
                    </span>
                  )}
                  <div style={{ color: '#ccc', fontWeight: '300', fontSize: '14px' }}>
                    <span style={{ float: 'left' }}>{slug}</span>
                    <span style={{ float: 'right' }}>
                      <span className={`${style.lastUpdated} ${postStyle.likeButton}`}>
                        <div className={postStyle.countBox}>{likes}</div>
                        <img
                          src={require(`../images/heart-${liked ? 'filled' : 'outline'}.png`)}
                          alt="like"
                          className="likeButton"
                          onClick={onLike}
                        />
                      </span>
                    </span>
                  </div>
                </section>

                <section className={style.noBorder} style={{ float: 'right', width: '40%' }}>
                  {image !== null && typeof image !== 'undefined' ? (
                    <img
                      src={`${API_ENDPOINT}${image}`}
                      style={{ width: '100%' }}
                      alt="Beispiel Bild"
                    />
                  ) : (
                    <span>Es wurde kein Bild hochgeladen</span>
                  )}
                  <MapModule
                    required
                    mapStyle={map.style}
                    zoom={15}
                    minZoom={15}
                    maxZoom={15}
                    marker={coordinates}
                    markerIcon={categoryObj.marker.file}
                    center={{ lng: coordinates.longitude, lat: coordinates.latitude }}
                    mapOnly={true}
                  />
                </section>
              </Container>
              <CommentSection slug={slug} />
            </StyledScrollbars>
          </div>
        </div>
      </div>
    </>
  );
};
