import React, { Component } from 'react';
import style from './mapbox-map.module.scss';
import { CreateLocalStorageProvider } from '../localstorageprovider';
import { StaticQuery, graphql } from 'gatsby';
require('mapbox-gl/dist/mapbox-gl.css');

let zoomStorage, centerStorage;

let LMap, GeoJSONLayer, ZoomControl;

export class MapboxMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lat: props.center[0],
      lng: props.center[1],
      zoom: props.zoom,
      mapStyle: props.mapStyle
    };

    this._mounted = false;
  }

  componentDidMount() {
    this._mounted = true;
    zoomStorage = CreateLocalStorageProvider('map-zoom');
    centerStorage = CreateLocalStorageProvider('map-center');

    const centerFromStorage = centerStorage.get();
    const zoom = parseFloat(zoomStorage.get()) || this.props.zoom;
    const lat =
      (centerFromStorage && parseFloat(JSON.parse(centerFromStorage).lat)) || this.props.center[0];
    const lng =
      (centerFromStorage && parseFloat(JSON.parse(centerFromStorage).lng)) || this.props.center[1];
    const ReactMapboxGl = require('react-mapbox-gl');

    this.setState({ zoom, lat, lng });

    GeoJSONLayer = ReactMapboxGl.GeoJSONLayer;
    LMap = ReactMapboxGl.Map({
      accessToken: 'pk.eyJ1IjoiY2hlZmZlbiIsImEiOiJDNDA5QkI0In0.tnM3DspM5Kesse6J0YrR_g',
      minZoom: this.props.minZoom || 11.8,
      maxZoom: this.props.maxZoom || 17
    });
    ZoomControl = ReactMapboxGl.ZoomControl;
    this.forceUpdate();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  onDragEnd = map => {
    const center = map.getCenter();
    centerStorage.set(JSON.stringify([center.lat, center.lng]));
  };

  onZoomEnd = map => {
    const zoom = map.getZoom();
    zoomStorage.set(zoom);
  };

  onStyleLoad = () => {
    if (this._mounted) {
      this.setState({
        lat: this.props.center[0],
        lng: this.props.center[1],
        zoom: this.props.zoom
      });
      zoomStorage.set(this.props.zoom);
      centerStorage.set(JSON.stringify(this.props.center));
    }
  };

  render() {
    const shouldUpdateMapPosition =
      typeof this.props.updatePosition === 'undefined' ? true : this.props.updatePosition;
    return (
      <StaticQuery
        query={graphql`
          {
            allDataJson(filter: { page: { eq: "map" } }) {
              edges {
                node {
                  map {
                    styles {
                      link
                      icon
                      title
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            <aside className={style.mapControls}>
              <ul>
                {data.allDataJson.edges[0].node.map.styles.map(item => {
                  return (
                    <li key={item.link} onClick={() => this.setState({ mapStyle: item.link })}>
                      <img
                        alt={item.title || ''}
                        src={require(`../${item.icon}`)}
                        className="likeButton"
                        title={item.title || ''}
                      />
                    </li>
                  );
                })}
              </ul>
            </aside>
            <div
              style={{
                textAlign: 'center',
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0
              }}
            >
              {LMap && ( // prevent Mapbox from being server side rendered
                <LMap
                  animate={true}
                  center={shouldUpdateMapPosition && { lat: this.state.lat, lng: this.state.lng }}
                  zoom={shouldUpdateMapPosition && [this.state.zoom]}
                  style={this.state.mapStyle}
                  className={style.map}
                  dragRotate={false}
                  onZoomEnd={this.onZoomEnd}
                  onDragEnd={this.onDragEnd}
                  // onClick={(_, e) => console.log([e.lngLat.lng, e.lngLat.lat])}
                  onStyleLoad={this.onStyleLoad}
                >
                  <ZoomControl
                    position="bottom-left"
                    style={{
                      bottom: 50
                    }}
                  />
                  {this.props.mapFeatures &&
                    this.props.mapFeatures.map(e => (
                      <GeoJSONLayer
                        key={e.shape[0]}
                        fillPaint={{
                          'fill-opacity': e.fillProperties.fill_opacity,
                          'fill-color': e.fillProperties.fill_color
                        }}
                        data={{
                          type: 'Feature',
                          geometry: {
                            type: 'Polygon',
                            coordinates: [e.shape]
                          }
                        }}
                      />
                    ))}
                  {this.props.children}
                </LMap>
              )}
            </div>
          </>
        )}
      />
    );
  }
}
